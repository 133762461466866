import React from 'react';
import Box from '@mui/material/Box';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';
import ServiceDataContainer from '../../components/services/ServiceDataContainer';
import MonitoringPrices from '../../components/pricing/MonitoringPrices';
import colors from '../../theme/colors';

const homeConsultationProps = {
    mainContainer: {
        padding: '0px 0px 47px',
    },
};

const serviceIntroductionData = {
    title: 'Nubi Monitoring Service',
    firstParagraph: 'Monitoreo en tiempo real e histórico de tus recursos de cómputo, almacenamiento y red. Controlá lo que estás consumiendo y tomá decisiones precisas acerca de tus recursos.',
    gatsbyImg: (<StaticImage src="../../images/bar-graphics.svg" alt="Gráficos" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};

const casesOfUseData = {
    title: 'Métricas principales',
    cases: [
        {
            image: (<StaticImage src="../../images/cpu_icon.svg" alt="VPN" />),
            text: 'CPU',
        },
        {
            image: (<StaticImage src="../../images/memory_icon.svg" alt="Túnel" />),
            text: 'Memoria',
        },
        {
            image: (<StaticImage src="../../images/storage_icon.svg" alt="Filtros" />),
            text: 'Disco',
        },
        {
            image: (<StaticImage src="../../images/network_icon.svg" alt="NATs" />),
            text: 'Placas de red',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 3,
    maxWidth: { maxWidth: '726px' },
    customPadding: '40px 136px 73px',
};

const servicesData = {
    services: [
        {
            image: (<StaticImage src="../../images/os_icon.svg" alt="SO" />),
            text: 'Compatible con SO Linux y Windows',
        },
        {
            image: (<StaticImage src="../../images/15_icon.svg" alt="15" />),
            text: 'Retención de hasta 15 días de datos.',
        },
    ],
    customContainerPadding: '60px 136px 32px',
};

const monitoringData = {
    isImage: false,
    title: 'Planes',
    customPadding: '0px 136px 30px',
    customMinHeight: '400px',
};

const Monitoring = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />
        <ServiceDataContainer servicesData={servicesData} />
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.darkViolet,
            }}
        >
            <MonitoringPrices monitoringData={monitoringData} />
        </Box>
        <HomeConsultation homeConsultationProps={homeConsultationProps} />
    </Layout>
);

export default Monitoring;

import React from 'react';
import {
    Grid, Box, Typography,
} from '@mui/material';
import colors from '../../theme/colors';

const serviceItemContainer = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
};

const alignTextCenter = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
};

const iconCenter = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const ServiceDataContainer = ({ servicesData }) => {
    const servicesDataWrapper = {
        maxWidth: '1440px',
        width: '100%',
        padding: servicesData.customContainerPadding
            ? servicesData.customContainerPadding
            : '60px 136px 0px',
    };

    return (
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: colors.darkViolet,
            }}
            sx={(theme) => ({ [theme.breakpoints.down('sm')]: { textAlign: 'center' } })}
        >
            <Box
                sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '20px 30px' }, ...servicesDataWrapper })}
            >
                <Box
                    sx={(theme) => ({ [theme.breakpoints.down('sm')]: { align: 'center' } })}
                >
                    <Typography
                        variant="subSectionH2"
                        mb={2}
                        style={{ width: '100%' }}
                    >
                        Datos del servicio
                    </Typography>
                </Box>
                <Grid container style={{ paddingBottom: '0px' }}>
                    {servicesData.services.map((service) => (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            mt={4}
                            sx={{ ...serviceItemContainer }}
                            key={service.text}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={2}
                                    sx={(theme) => ({ [theme.breakpoints.down('sm')]: { paddingBottom: '16px' }, ...iconCenter })}
                                >
                                    <Box style={{ width: '72px' }}>
                                        {service.image}
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                    px={2}
                                >
                                    <Typography
                                        variant="h4"
                                        style={{ ...alignTextCenter, lineHeight: '29px' }}
                                    >
                                        {service.text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default ServiceDataContainer;

import React from 'react';
import {
    Grid, Box, Typography,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import colors from '../../theme/colors';

const monitoringServiceContainer = {
    minHeight: '371px',
    maxWidth: '322px',
};
const titleContainer = {
    width: '100%',
    minHeight: '106px',
    background: colors.violet,
    padding: '30px 35px 23px 30px',
    borderRadius: '10px 10px 0px 0px',
    textAlign: 'left',
};
const infoContainer = {
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    padding: '29px 35px 34px 30px',
    minHeight: '265px',
    width: '100%',
    borderRadius: '0px 0px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
};
const retentionTextStyle = {
    fontSize: '17px', fontWeight: 600, fontFamily: 'Montserrat', lineHeight: '20.72px',
};
const customTextContainer = {
    display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'baseline',
};

const MonitoringPrices = ({ monitoringData }) => {
    const monitoringWrapper = {
        padding: monitoringData.customPadding,
        maxWidth: '1440px',
        width: '100%',
        minHeight: monitoringData.customMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
    };

    return (
        <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '48px 24px' }, ...monitoringWrapper })}>
            {monitoringData.isImage && (
                <Box style={{ width: '150px' }}>
                    <StaticImage src="../../images/bar-graphics.svg" alt="Computadora" />
                </Box>
            )}
            <Typography variant="sectionH2" pb={4} pt={6}>
                {monitoringData.title}
            </Typography>
            <Box>
                <Grid container>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        sx={(theme) => ({
                            [theme.breakpoints.down('md')]: {
                                paddingRight: '0px',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '20px',
                            },
                            paddingRight: '24px',
                        })}
                    >
                        <Box sx={{ ...monitoringServiceContainer }}>
                            <Box sx={{ ...titleContainer }}>
                                <Typography variant="h4" style={{ fontFamily: 'Montserrat', lineHeight: '24px' }}>
                                    Basic Nubi Monitoring Service
                                </Typography>
                            </Box>
                            <Box sx={{ ...infoContainer }}>
                                <Box>
                                    <Typography variant="h4" style={{ ...retentionTextStyle }} mb={2}>
                                        Retención de 15 días
                                    </Typography>
                                    <Typography variant="h4" style={{ ...retentionTextStyle, lineHeight: '22px' }}>
                                        Métricas básicas sobre CPU, RAM, Disco y Red
                                    </Typography>
                                </Box>
                                <Typography variant="h4" style={{ fontWeight: 600, fontFamily: 'Montserrat', lineHeight: '24.38px' }}>
                                    Servicio gratuito
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        sx={(theme) => ({
                            [theme.breakpoints.down('md')]: {
                                paddingLeft: '0px',
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '20px',
                            },
                            paddingLeft: '24px',
                        })}
                    >
                        <Box sx={{ ...monitoringServiceContainer }}>
                            <Box sx={{ ...titleContainer }}>
                                <Typography variant="h4" style={{ fontFamily: 'Montserrat', lineHeight: '24px' }}>
                                    Full Nubi Monitoring Service
                                </Typography>
                            </Box>
                            <Box sx={{ ...infoContainer }}>
                                <Box>
                                    <Typography variant="h4" style={{ ...retentionTextStyle }} mb={2}>
                                        Retención de 1 año
                                    </Typography>
                                    <Typography variant="h4" style={{ ...retentionTextStyle, lineHeight: '22px' }}>
                                        Métricas avanzadas sobre CPU, RAM, Disco, Red, SO y aplicaciones
                                    </Typography>
                                </Box>
                                <Box sx={{ ...customTextContainer }}>
                                    <Typography variant="h4" style={{ fontWeight: 600, fontFamily: 'Montserrat', lineHeight: '24.38px' }}>
                                        4 USD /&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle2" style={{ fontWeight: 600, fontFamily: 'Montserrat' }}>
                                        máquina virtual al mes
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default MonitoringPrices;
